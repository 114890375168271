<template>
  <div>
    <div v-if="!show.id" class="mt-12 max-w-md mx-auto flex justify-center">
      <loading-icon class="h-10 w-10 text-pink-500" />
    </div>
    <div v-else class="flex flex-col mt-12 w-full">
      <div>
        <div>
          <router-link :to="{ name: 'ShowIndex' }" class="back-btn">
            <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" class="arrow-narrow-left w-5 h-5">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16l-4-4m0 0l4-4m-4 4h18">
              </path>
            </svg>
            <span class="ml-2">back</span>
          </router-link>
        </div>
      </div>
      <div class="flex flex-col xl:flex-row w-full">
        <div class="mt-6 mx-auto">
          <profile-navigation :isShow="true" @changeSection="changeSection" />
        </div>
        <div class="flex flex-col w-full mt-6 bg-white shadow-xl rounded-br-xl rounded-bl-xl overflow-auto">
          <div class="flex flex-col relative w-full h-14 bg-blue-800 rounded-tr-xl rounded-tl-xl">
            <div class="flex flex-row h-full justify-start">
              <h1 class="text-white text-lg font-semibold ml-12 my-auto">{{ sectionName }}</h1>
              <ToggleSwitch v-if="section == 2" :active="pitchSettings && pitchSettings.is_enabled"
                @toggled="togglePitch" />
            </div>
          </div>

          <div v-if="section == 1">
            <div class="details-and-presskit mx-8 md:mx-13">
              <div class="details-item">
                <show-name :show="show" />
              </div>

              <div class="md:pt-6">
                <show-type :show="show" />
              </div>

              <div class="details-item">
                <press-kit-rss :model="show" entity="show" />
              </div>

              <div class="details-item">
                <show-image :show="show" />
              </div>

              <div v-if="user.account_type == 'pro'" class="details-item">
                <show-cover-image :show="show" />
              </div>

              <div class="details-item">
                <profile-categories :modelId="show.id" entity="show" />
              </div>

              <div class="details-item">
                <show-tags :show="show" />
              </div>

              <!-- <div class="details-item">
                <media-kit-link :model="show" entity="show" />
              </div> -->

              <div class="details-item">
                <show-social-media-links />
              </div>

              <div class="details-item">
                <show-episode-links />
              </div>

              <div class="details-item">
                <show-media-feature-links />
              </div>

              <div class="details-item">
                <show-past-guests :show="show" />
              </div>

              <div class="details-item">
                <show-visibility :show="show" />
              </div>

              <div class="details-item">
                <press-kit-bio :model="show" entity="show" />
              </div>

              <div class="details-item">
                <show-stats :show="show" @refresh="fetchShow()" />
              </div>

              <div class="details-item">
                <press-kit-logos :model="show" entity="show" />
              </div>

              <div class="details-item">
                <press-kit-photos :model="show" entity="show" />
              </div>
            </div>
          </div>

          <div v-if="section == 2" class="border-b border-gray-300 mt-10 pb-10 mx-13">
            <profile-pitch :isEnabled="pitchSettings.is_enabled" :model="show" entity="shows" />
          </div>

          <div v-if="section == 3" class="mx-13">
            <div class="mt-10 border-b border-gray-300">
              <show-booking-options :show="show" />
            </div>

          </div>

          <div v-if="section == 2">
            <div class="relative px-13">
              <div class="absolute ml-28 mt-7">
                <ToggleSwitch v-if="section == 2" :active="pitchSettings.questions" @toggled="togglePitchApplication" />
              </div>
              <pitch-questions :isEnabled="pitchSettings.questions" :model="show" entity="show" />
            </div>
            <div class="w-7/8 h-0 mx-13 border-b border-gray-300"></div>
          </div>

          <div v-if="section == 3">
            <div class="relative px-13">
              <div class="absolute ml-28 mt-7">
                <ToggleSwitch v-if="section == 3" :active="applicationEnabled" @toggled="toggleBookingApplication" />
              </div>
              <booking-questions :isEnabled="applicationEnabled" :model="show" entity="show" />
            </div>
            <div class="w-7/8 h-0 mx-13 border-b border-gray-300"></div>
          </div>

          <div v-if="section == 3" class="mt-7 mb-4 mx-13">
            <div>
              <upsell-packages :isEnabled="upsellsEnabled" :model="show" entity="show" @toggleUpsells="toggleUpsells" />
            </div>
          </div>

          <div v-if="section == 4">
            <div class="mt-8 mx-4 md:mx-13">
              <show-pro-stats />
            </div>
          </div>

          <div v-if="section == 5">
            <div class="mt-8 mx-13" v-if="user.account_type == 'pro'">
              <show-pro-settings />
            </div>
          </div>

          <div v-if="section == 8">
            <div class="mt-8 mx-13">
              <delete-profile :model="show" entity="show" />
            </div>
          </div>
        </div>
      </div>

    </div>



  </div>
</template>

<script>
import { mapState } from 'vuex';
import * as ShowModules from '@/components/details/show'
import DeleteProfile from '@/components/details/DeleteProfile'
import { PressKitRss, PressKitBio, PressKitLogos, PressKitPhotos } from '@/components/details/press-kit'
import ProfileNavigation from '@/components/details/ProfileNavigation'
import BookingQuestions from '@/components/details/pro/BookingQuestions.vue';
import PitchQuestions from '@/components/details/pro/PitchQuestions.vue';
import UpsellPackages from '@/components/details/pro/UpsellPackages.vue';
import ToggleSwitch from '@/components/shared/ToggleSwitch';

export default {
  components: {
    ...ShowModules,
    PressKitRss,
    PressKitBio,
    PressKitLogos,
    PressKitPhotos,
    DeleteProfile,
    ProfileNavigation,
    BookingQuestions,
    UpsellPackages,
    ToggleSwitch,
    PitchQuestions,
  },

  data() {
    return {
      working: false,
      section: '1',
      applicationEnabled: true,
      upsellsEnabled: false,
      bookingFlowPreferences: {},
      pitchSettings: {},
    }
  },

  computed: {
    ...mapState({
      user: state => state.auth.user
    }),

    show() {
      return this.$store.getters['shows/show']
    },

    sectionName() {
      switch (this.section) {
        case 1:
          return "Guest Details & Presskit";
        case 2:
          return "Pitch";
        case 3:
          return "Booking Flow Steps";
        case 4:
          return "Pro Settings";
        case 5:
          return "Booking Links";
        case 6:
          return "Danger Zone";
        default:
          return "Guest Details & Presskit";
      }
    }
  },

  async created() {
    await this.fetchShow();

    this.$store
      .dispatch('shows/fetchPitchSettings', this.$route.params.showId)
      .then(({ data }) => {
        this.pitchSettings = data.data
      });

    this.$store.dispatch('shows/fetchBookingFlowPreferences', this.$route.params.showId).then((data) => {
      this.bookingFlowPreferences = data.data.data
      this.applicationEnabled = this.bookingFlowPreferences.questions;
      this.upsellsEnabled = this.bookingFlowPreferences.upsells;
    })
  },

  destroyed() {
    this.$store.commit('shows/clearShow')
  },

  methods: {
    async fetchShow() {
      try {
        await this.$store.dispatch('shows/fetchShow', {
          id: this.$route.params.showId,
          includes: 'pastGuests,showTypes,profileStatsSettings'
        })
      } catch (error) {
        if (error.response && error.response.status === 404) {
          return this.$router.push({ name: 'ShowIndex' })
        }

        this.$toast.error('Error! Something went wrong.')
      }
    },

    changeSection(index) {
      this.section = index;
    },

    togglePitch(state) {
      this.$store.dispatch('shows/updatePitchSettings', {
        modelId: this.show.id,
        is_enabled: state,
      }).then(() => {
        this.pitchSettings.is_enabled = state;
        this.$toast.success(state ? 'Pitch enabled' : 'Pitch disabled')
      })
    },


    toggleBookingApplication() {
      this.bookingFlowPreferences.questions = !this.bookingFlowPreferences.questions;

      this.$store.dispatch('shows/updateBookingFlowPreferences', { showId: this.$route.params.showId, updatedPreferences: this.bookingFlowPreferences }).then(() => {
        this.applicationEnabled = this.bookingFlowPreferences.questions;
        this.$toast.success(this.applicationEnabled ? 'Application enabled' : 'Application disabled')
      })
    },

    togglePitchApplication() {
      this.$store.dispatch('shows/updatePitchSettings', {
        modelId: this.show.id,
        questions: !this.pitchSettings.questions,
      }).then(() => {
        this.pitchSettings.questions = !this.pitchSettings.questions;
        this.$toast.success(this.pitchSettings.questions ? 'Application enabled' : 'Application disabled')
      })
    },

    toggleUpsells() {
      this.bookingFlowPreferences.upsells = !this.bookingFlowPreferences.upsells;

      this.$store.dispatch('shows/updateBookingFlowPreferences', { showId: this.$route.params.showId, updatedPreferences: this.bookingFlowPreferences }).then(() => {
        this.upsellsEnabled = this.bookingFlowPreferences.upsells;
        this.$toast.success(this.upsellsEnabled ? 'Upsells enabled' : 'Upsells disabled')
      })
    },
  }
}
</script>

<style scoped>
.back-btn {
  @apply text-gray-500 uppercase font-bold tracking-wide text-sm inline-flex items-center justify-center focus:outline-none focus:underline hover:text-gray-800 transition duration-150 ease-in-out;
}

.details-and-presskit>div {
  @apply mb-4 md:mb-auto border-b border-gray-300;
}

.details-item {
  @apply py-1 md:py-6;
}
</style>
